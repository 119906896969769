import moment from "moment";

export const dateFormat = (date) => {
  if (date && date.split(' ').join('') !== '' && date.split(' ').join('') !== '0') {
    date = moment(date).format('YYYY/MM/DD')
    return date;
  } else {
    return "";
  }
};

export const formatNumber = (num) => {
  if (num) {
    const b = parseInt(num).toString();
    const len = b.length;
    if (len <= 3) {
      return b;
    }
    const r = len % 3;
    return r > 0
      ? b.slice(0, r) + ',' + b.slice(r, len).match(/\d{3}/g).join(',')
      : b.slice(r, len).match(/\d{3}/g).join(',');
  } else {
    return 0;
  }
};
